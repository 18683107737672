html {
    scroll-behavior: smooth;
  }
  
  /* kidly move the following css to relative place/file */
  .errorCkEditor {
    border: 1px solid red;
    border-radius: 3px !important;
  }
  .loginBtn{
    background-color: #000000f3;
    color:'white'
  }
  .loginBtn:hover {
    background-color: #000000de;
    color: white;
  }
 
  .mauto {
    margin: 0 auto;
  }
  
  
  
  /* EDIT NEWS PAGE */
  
  .uploaded-media{
    width: 100%;
    height: 230px;
    display: flex;
  
  }
  
  .uploaded-media img, .uploaded-media video{
    max-height: 180px;
    display: block;
    margin: auto;
  }
  
  
  /* border heading design */
  
  .border-radius-0 .MuiPaper-root{
    border-radius: 0px;
  }

  th.arhieved_head_title, tr.arhieved_head_title {
    width: 47% !important;
    vertical-align: top;
  }

.word-wrap, th p, td p{
  word-wrap: break-word;
  word-break: break-all;
}

th.cu_first_name, th.cu_last_name, td.cu_first_name, td.cu_last_name, th.cu_action, td.cu_action{
  max-width: 200px !important;
  width: 200px !important;
  vertical-align: top;
}
td.cu_email, td.cu_role{
  vertical-align: top;
}

.news_bulletin_srno{
  width: 150px !important;
}

.news_sponsors{
  width: 350px !important;
  max-width: 350px !important;
}
.tbl-pagination p {
  margin-bottom: 0 !important;
}

input::-ms-reveal,
input::-ms-clear {
  display: none;
}