.reset-link-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  /* backdrop-filter: blur(2px); */
  z-index: 30;
  opacity: 0;
  visibility: hidden;
  /* transition: all 0.1s ease; */
}
.popup-wrapper {
  background-color: #fff;
  max-width: 700px;
  padding: 30px 107px;
  border-radius: 20px;
  text-align: center;
}
.popup-wrapper svg path {
  fill: #000;
}
.reset-link-popup.active {
  opacity: 1 !important;
  visibility: visible !important;
}
.reset-link-popup.active_modal {
  opacity: 1 !important;
  visibility: visible !important;
}
.popup-wrapper a {
  border: none;
  border-radius: 40px;
  color: #fff;
  display: inline-block;
  font-size: 1rem !important;
  background: #000;
  font-weight: 400;
  min-width: 220px;
  overflow: hidden;
  padding: 12px 28px !important;
  position: relative;
  text-transform: capitalize;
  z-index: 1;
}
.popup-wrapper button {
  border: none;
  border-radius: 40px;
  color: #fff;
  display: inline-block;
  font-size: 1rem !important;
  background: #000;
  font-weight: 400;
  min-width: 170px;
  overflow: hidden;
  padding: 12px 28px !important;
  position: relative;
  text-transform: capitalize;
  z-index: 1;
}

.popup-wrapper button.cancel_btn {
  border: none;
  border-radius: 40px;
  color: #000;
  display: inline-block;
  font-size: 1rem !important;
  background: #fdd417;
  font-weight: 400;
  min-width: 170px;
  overflow: hidden;
  padding: 12px 28px !important;
  position: relative;
  text-transform: capitalize;
  z-index: 1;
  margin-left: 10px;
}
.popup-wrapper button:hover {
  color: #000;
  background-color: #fdd417;
}
.popup-wrapper button.cancel_btn:hover {
  color: #000;
  background-color: #fff;
  border: 1px solid #000;
}
.popup-wrapper p {
  font-size: 20px;
  font-weight: bold;
}
.containerDiv {
  overflow-x: auto;
  display: flex;
  justify-content: center;
  position: relative;
  top: 10px;
}

.pagination {
  display: flex;
  align-items: center;
  padding: 0px 10px;
  height: 42px;
}
.breakLink {
  pointer-events: none;
  margin: 0px 2px;
  color: #8b8b8b !important;
}

.active_modal {
  background-color: #000;
  border: 1px solid #000 !important;
  color: white !important;
}

.active {
  background-color: rgba(145, 158, 171, 0.12);
  border: 1px solid rgba(145, 158, 171, 0.12) !important;
  color: white !important;
}
.pageLink {
  color: #8b8b8b;
  width: 100%;
  height: 99%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.pageLink :hover {
  text-decoration: none;
}
.pageItem :hover {
  text-decoration: none;
  color: #8b8b8b;
}
.pageItem {
  margin: 0px 5px;
  font-weight: 500;
  font-size: 12px;
  width: 35px;
  height: 35px;
  overflow: hidden;
  border: 1px solid #bebebe;
  border-radius: 0%;

  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  -ms-border-radius: 50%;
  -o-border-radius: 50%;
}

.previousNext {
  background-color: transparent;
  margin: 0px 5px;
  cursor: pointer;
}
/* .main_loading {
  position: relative;
  height: 100%;
}
.main_loading_child {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
} */

.verify_btn {
  margin-left: 10px !important;
}
.verify_btn_users {
  background: transparent !important;
  border: 0 !important;
}
.loading_main {
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  top: 50%;
  transform: translateY(-50%);
}
.loading_inner {
  text-align: center;
}
.form-group {
  margin-bottom: 35px;
}
.primary-clr {
  color: #000;
}
.role {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
.role__blk input {
  display: none;
}
.form_main input {
  border: none;
  border-bottom: 1px solid rgba(0, 0, 0, 0.5);
  width: 100%;
  font-size: 0.938rem;
  height: 40px;
}
.role__blk label {
  height: 50px;
  border: 1px solid #d9d9d9;
  border-radius: 50px;
  line-height: 50px;
  display: block;
  text-align: center;
  color: #000000;
  cursor: pointer;
}
.role__blk input:checked + label {
  background: #fdd417;
  border-color: #fdd417;
}
.role__blk:nth-child(2) input:checked + label {
  color: #fff;
  background: #1790ff;
  border-color: #1790ff;
}
.role__blk:nth-child(3) input:checked + label {
  color: #fff;
  background: #08b652;
  border-color: #08b652;
}
.edit_popup p {
  font-size: 14px !important;
}
.active_btn {
  background: #000000;
  color: #fff;
  margin-left: 14px;
}
.btn_filter {
  margin-left: 14px;
}

/* button filter */

/* button:hover, */
.popup-wrapper .btn:hover,
button:focus {
  /* background-color: #fdd417 !important; */
  color: #000 !important;
}

.dashboard-tab.Mui-selected {
  color: #ffffff;
}

.dashboard-tab:focus {
  color: #ffffff !important;
}

.filters_users {
  padding: 15px 0;
}

.filters_users button:nth-child(2):hover,
.filters_users button:nth-child(2).active_btn {
  background-color: #b78103 !important;
  color: #fff !important;
  border-color: #b78103 !important;
}

.filters_users button:nth-child(3):hover,
.filters_users button:nth-child(3).active_btn {
  background-color: #103996 !important;
  color: #fff !important;
  border-color: #103996 !important;
}

.filters_users button:nth-child(4):hover,
.filters_users button:nth-child(4).active_btn {
  background-color: #229a16 !important;
  color: #fff !important;
  border-color: #229a16 !important;
}

body .text-primary {
  color: #212b36 !important;
  text-decoration: none;
}

.thumb-box > div {
  overflow: hidden !important;
}
.thumb-box img {
  transition: all 0.5s linear;
}

.thumb-box:hover img {
  transform: scale(1.2);
}

/* table first cell padding */

.table-spacer tr > th:nth-child(1) {
  padding: 16px !important;
}

a.back_link {
  color: #fff;
  background-color: #000000;
  text-decoration: none;
  padding: 6px 20px;
  border-radius: 6px;
  font-size: 16px;
  text-transform: uppercase;
}
.link_back_div {
  padding: 15px 0;
}

.close-icon {
  cursor: pointer;
}
p.custom_para {
  margin: 0;
}
p.custom_para p {
  margin: 0 !important;
}

.borderInput {
  border: 1px solid #c1c8d0 !important;
}

.errorForm {
  color: #ff4842;
  line-height: 1.5;
  font-size: 0.75rem;
  font-family: Public Sans, sans-serif;
  font-weight: 400;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.sideBar:hover {
  color: white;
}

.alertStyle:hover {
  background-color: black;
  color: white;
}

.bulletin-error {
  color: #ff4d47 !important;
}

.MuiFormHelperText-contained.bulletin-error {
  font-size: 0.75rem !important;
  line-height: 1.5;
  font-size: 0.75rem;
  font-family: Public Sans, sans-serif;
  font-weight: 400;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
  margin-left: 14px;
}

.description_div .ck-editor__main {
  height: 180px !important;
}
.description_div .ck-editor__editable:focus {
  outline: none; /* Removes the default focus outline */
  border: 2px solid black; /* Add black border */
}
.ck-focused {
  border: 2px solid #000 !important;
}
.h-100p {
  height: 100px !important;
}

.img-preview-wrap {
  position: relative;
}
.delete-icon {
  position: absolute;
  top: -13px;
  right: -10px;
  color: white;
  background-color: #dc3545;
}

.Product_Table tbody td label {
  font-size: 14px;
}
