.list-select-images{
    position: relative;
    margin-right: 15px;
}
.list-select-images img{border-radius: 3px;}


.trash-img{position: absolute;
    background: red;
    top: -8px;
    right: -8px;
    z-index: 1;
    color: #fff;
    border-radius: 50%;
    /* padding: 2px; */
    cursor: pointer;
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;}

    .trash-img svg{width:20px;}